import { useEffect, useRef } from "react";
import { Box, Label, get as getTheme, useThemeUI } from "theme-ui";
import "toolcool-range-slider";
import type { OceanExplorerTheme } from "./theme";
import type { OeComponentBase } from "./OeComponentBase";
import { cloneDeep } from "lodash";

//// this is required ... from the github page for the range
//// slider: https://github.com/toolcool-org/toolcool-range-slider#usage-with-react-and-typescript
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "toolcool-range-slider": any;
    }
  }
}

export interface OeRangeProps extends OeComponentBase {
  labelText?: string;
  onChange?: (lowerValue: number, upperValue: number) => void;
  variant?: keyof OceanExplorerTheme["sliders"]; // defaults to primary
  varText?: string;
  step?: number;
  round?: number;
  rangeMin?: number;
  rangeMax?: number;
  valueLow?: number;
  valueHigh?: number;
}

export const OeRange = (props: OeRangeProps) => {
  // this is mostly boilerplate from the Usage with React and typescript
  // section of the github page
  // https://github.com/toolcool-org/toolcool-range-slider#usage-with-react-and-typescript
  // all we've done is wrap it as a mobx observer
  const sliderRef = useRef<HTMLElement>();

  // lastChangeTime will be used as a guard against firing too many
  // onChange callbacks per second
  let lastChangeTime = Date.now();
  const timeGuardMS = 0;

  useEffect(() => {
    const slider = sliderRef.current;

    const onChange = (evt: Event) => {
      const timeNow = Date.now();

      if (timeNow - lastChangeTime < timeGuardMS) {
        // early return to guard against too many onChanges per second...
        return;
      }
      const customEvent = evt as CustomEvent;
      //const value1 = Math.round(customEvent.detail.value);
      //const value2 = Math.round(customEvent.detail.value2);
      const value1 = customEvent.detail.value;
      const value2 = customEvent.detail.value2;
      if (props.onChange !== undefined) {
        props.onChange(value1, value2);
      }
      lastChangeTime = timeNow;
    };

    slider?.addEventListener("change", onChange);

    return () => {
      slider?.removeEventListener("change", onChange);
    };
  }, []);

  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const sliderStyles: {
    [k in keyof OceanExplorerTheme["sliders"]["primary"]]: string;
  } = cloneDeep(theme.sliders[props.variant ?? "primary"]);

  for (const [k, v] of Object.entries<string>(sliderStyles)) {
    sliderStyles[k as keyof OceanExplorerTheme["sliders"]["primary"]] =
      getTheme(theme, "colors." + v, v);
  }

  let min = 0;
  let max = 255;
  if (
    props.rangeMin !== undefined &&
    props.rangeMax !== undefined &&
    props.rangeMin < props.rangeMax
  ) {
    min = props.rangeMin;
    max = props.rangeMax;
  }

  let low = min;
  let high = max;
  if (
    props.valueLow !== undefined &&
    props.valueHigh !== undefined &&
    props.valueLow < props.valueHigh
  ) {
    low = Math.max(props.valueLow, low);
    high = Math.min(props.valueHigh, high);
  }

  return (
    <Box sx={{ width: "100%", ...props.sx }}>
      {props.labelText !== undefined && (
        <Label sx={{ marginBottom: 4 }}>{props.labelText}</Label>
      )}
      {/* TODO - adjusting margin/border not updating correctly */}
      {props.varText !== undefined && (
        <Label sx={{ marginBottom: 4, color: theme.colors.muted }}>
          {props.varText}
        </Label>
      )}
      <toolcool-range-slider
        style={{ paddingTop: "2px" }}
        ref={sliderRef}
        min={min}
        max={max}
        value1={low}
        value2={high}
        step={props.step}
        round={props.round ?? 0}
        pointers-min-distance="1"
        pointer-width="0.75rem"
        pointer-height="2rem"
        range-dragging="true"
        theme="rect"
        slider-width="100%"
        slider-bg={sliderStyles["slider-bg"]}
        slider-bg-fill={sliderStyles["slider-bg-fill"]}
        slider-bg-hover={sliderStyles["slider-bg-hover"]}
        slider-bg-focus={sliderStyles["slider-bg-focus"]}
        pointer-bg={sliderStyles["pointer-bg"]}
        pointer-bg-hover={sliderStyles["pointer-bg-hover"]}
        pointer-bg-focus={sliderStyles["pointer-bg-focus"]}
      />
    </Box>
  );
};
