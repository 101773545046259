import { get as themeGet, useThemeUI } from "theme-ui";
import type { OeComponentBase } from "./OeComponentBase";

interface IconSvgPathProps {
  fill: string;
}

// We need the type def here to be compatible with `path`'s props
const commonIconProps: Record<string, string> = {
  opacity: "0.4",
  fillRule: "evenodd",
  clipRule: "evenodd",
  alt: "string",
};

// Select one or more SVGs in Figma, right click, Copy as SVG
const OeIconOptions = {
  globe: {
    width: 73,
    height: 72,
    alt: "Globe",
    svgPath: (props: IconSvgPathProps) => (
      <path
        {...commonIconProps}
        d="M6.64813 33C7.76635 21.7374 15.1134 12.3071 25.1956 8.20278C20.6604 13.3895 16.2408 21.3862 15.5838 33H6.64813ZM6.64813 39C7.76635 50.2626 15.1134 59.6929 25.1956 63.7972C20.6604 58.6105 16.2408 50.6138 15.5838 39H6.64813ZM21.5947 39C22.2376 49.0135 26.0462 55.6724 29.7543 59.8956C31.0492 61.3703 32.3418 62.5584 33.5 63.4918V39H21.5947ZM39.5 39V63.4918C40.6582 62.5584 41.9508 61.3703 43.2457 59.8956C46.9538 55.6724 50.7624 49.0135 51.4053 39H39.5ZM51.4053 33H39.5V8.50824C40.6582 9.44156 41.9508 10.6297 43.2457 12.1044C46.9538 16.3276 50.7624 22.9865 51.4053 33ZM57.4162 39C56.7592 50.6138 52.3396 58.6105 47.8044 63.7972C57.8866 59.6929 65.2337 50.2626 66.3519 39H57.4162ZM66.3519 33H57.4162C56.7592 21.3862 52.3396 13.3895 47.8044 8.20278C57.8866 12.3071 65.2337 21.7374 66.3519 33ZM33.5 33H21.5947C22.2376 22.9865 26.0462 16.3276 29.7543 12.1044C31.0492 10.6297 32.3418 9.44156 33.5 8.50824V33ZM36.5 72C56.3823 72 72.5 55.8823 72.5 36C72.5 16.1177 56.3823 0 36.5 0C16.6177 0 0.5 16.1177 0.5 36C0.5 55.8823 16.6177 72 36.5 72Z"
        fill={props.fill}
      />
    ),
  },
  "globe-plus": {
    width: 88,
    height: 87,
    alt: "Globe with a plus sign",
    svgPath: (props: IconSvgPathProps) => (
      <>
        <path
          {...commonIconProps}
          d="M75 0C74.4477 0 74 0.447716 74 1V8H67C66.4477 8 66 8.44772 66 9V13C66 13.5523 66.4477 14 67 14H74V21C74 21.5523 74.4477 22 75 22H79C79.5523 22 80 21.5523 80 21V14H87C87.5523 14 88 13.5523 88 13V9C88 8.44771 87.5523 8 87 8H80V1C80 0.447715 79.5523 0 79 0H75Z"
          fill={props.fill}
        />
        <path
          {...commonIconProps}
          d="M6.64813 48C7.76635 36.7374 15.1134 27.3071 25.1956 23.2028C20.6604 28.3895 16.2408 36.3862 15.5838 48H6.64813ZM6.64813 54C7.76635 65.2626 15.1134 74.6929 25.1956 78.7972C20.6604 73.6105 16.2408 65.6138 15.5838 54H6.64813ZM21.5947 54C22.2376 64.0135 26.0462 70.6724 29.7543 74.8956C31.0492 76.3703 32.3418 77.5584 33.5 78.4918V54H21.5947ZM39.5 54V78.4918C40.6582 77.5584 41.9508 76.3703 43.2457 74.8956C46.9538 70.6724 50.7624 64.0135 51.4053 54H39.5ZM51.4053 48H39.5V23.5082C40.6582 24.4416 41.9508 25.6297 43.2457 27.1044C46.9538 31.3276 50.7624 37.9865 51.4053 48ZM57.4162 54C56.7592 65.6138 52.3396 73.6105 47.8044 78.7972C57.8866 74.6929 65.2337 65.2626 66.3519 54H57.4162ZM66.3519 48H57.4162C56.7592 36.3862 52.3396 28.3895 47.8044 23.2028C57.8866 27.3071 65.2337 36.7374 66.3519 48ZM33.5 48H21.5947C22.2376 37.9865 26.0462 31.3276 29.7543 27.1044C31.0492 25.6297 32.3418 24.4416 33.5 23.5082V48ZM36.5 87C56.3823 87 72.5 70.8823 72.5 51C72.5 31.1177 56.3823 15 36.5 15C16.6177 15 0.5 31.1177 0.5 51C0.5 70.8823 16.6177 87 36.5 87Z"
          fill={props.fill}
        />
      </>
    ),
  },
  arrow: {
    width: 20,
    height: 12,
    alt: "Arrow",
    svgPath: (props: IconSvgPathProps) => (
      <>
        <g transform="scale(2 2)">
          <path
            {...commonIconProps}
            d="M5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683418 6.09763 0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421Z"
            fill={props.fill}
          />
        </g>
      </>
    ),
  },
  "upsidedown-arrow": {
    width: 40,
    height: 40,
    alt: "Upsidedown Arrow",
    svgPath: (props: IconSvgPathProps) => (
      <>
        <g transform="scale(2, 2)">
          <path
            {...commonIconProps}
            d="M10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858Z"
            fill={props.fill}
          />
        </g>
      </>
    ),
  },
};

export type OeIconName = keyof typeof OeIconOptions;
export const OeIconNames: OeIconName[] = Object.keys(
  OeIconOptions,
) as OeIconName[];
interface OeIconProps extends OeComponentBase {
  iconName: OeIconName;
  alt?: string; // override icon's default alt text
}

export function OeIcon(props: OeIconProps) {
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const selectedIcon = OeIconOptions[props.iconName];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={selectedIcon.width}
      height={selectedIcon.height}
      viewBox={`0 0 ${selectedIcon.width} ${selectedIcon.height}`}
      fill="none"
      role="img"
    >
      <title>{props.alt ?? selectedIcon.alt}</title>
      <selectedIcon.svgPath
        fill={themeGet(theme.rawColors!, themeGet(theme, "icons.primary.fill"))}
      />
    </svg>
  );
}
