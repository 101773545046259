import type { Datasource, Variable } from "oex-common-model";
import {
  OeCalendar,
  OeCheckboxList,
  type OceanExplorerTheme,
} from "oex-common-ui";
import type { OptionObject } from "oex-common-ui/lib/OeCheckboxList";
import { useState } from "react";
import { Link as HashLink } from "react-router-dom";
import type { ThemeUIStyleObject } from "theme-ui";
import { Box, Button, Divider, Input, Label, Text, useThemeUI } from "theme-ui";
import { PrivacyPageRoute } from "../PrivacyPage";
import { DataRequestEmailButton } from "./DataRequestEmailButton";
import type { Coordinates } from "./PublicDataRequestState";
import { validateCoordinates } from "./PublicDataRequestState";
import type { StageName } from "./stageNames";

const inputStyle: ThemeUIStyleObject = {
  borderRadius: 2,
  height: "50px",
};

const TransitionButton = (props: {
  disabled?: boolean;
  nextStage: React.MouseEventHandler<HTMLButtonElement>;
  text?: string;
}) => {
  return (
    <Button
      variant="primary"
      sx={{ borderRadius: 2, height: "55px" }}
      disabled={props.disabled}
      onClick={props.nextStage}
    >
      {props.text}
    </Button>
  );
};

const Region = (props: {
  header: string;
  x: number;
  y: number;
  xChange: React.ChangeEventHandler<HTMLInputElement>;
  yChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  // Needed to specify which label is for which input
  const latId = `${props.header.toLowerCase().replace("w", "-")}-latitude`;
  const lonId = `${props.header.toLowerCase().replace("w", "-")}-longitude`;
  const secondaryLabelStyle = { paddingTop: "8px", paddingBottom: "4px" };

  return (
    <Box>
      <Label variant="forms.labels.primary" sx={{ paddingBottom: "8px" }}>
        {props.header}
      </Label>
      <Label
        variant="forms.labels.secondary"
        sx={secondaryLabelStyle}
        htmlFor={latId}
      >
        Latitude
      </Label>
      <Input
        id={latId}
        variant="primary"
        sx={inputStyle}
        placeholder="Latitude"
        value={props.x}
        onChange={props.xChange}
        type="number"
        step="0.1"
      />

      <Label
        variant="forms.labels.secondary"
        sx={secondaryLabelStyle}
        htmlFor={lonId}
      >
        Longitude
      </Label>
      <Input
        id={lonId}
        variant="primary"
        sx={inputStyle}
        placeholder="Longitude"
        value={props.y}
        onChange={props.yChange}
        type="number"
        step="0.1"
      />
    </Box>
  );
};

export const ControlPanelBody = (props: {
  coordinates: Coordinates;
  onCoordinateChange: (coordinateUpdate: Partial<Coordinates>) => void;
  stageName: StageName;
  setStage: (stage: StageName) => void;
  selectedDatasources?: string[];
  validDatasources: Datasource[];
  invalidDatasources: Datasource[];
  onDatasourceSelectionChange: (selectionChange: [string, boolean]) => void;
  onVariableSelectionChange: (selectionChange: [Variable, boolean]) => void;
  selectedVariables: string[];
  startDate: Date;
  endDate: Date;
  selectedDates: string;
  onDateChange: ({
    startDate,
    endDate,
    formattedDate,
  }: {
    startDate?: Date;
    endDate?: Date;
    formattedDate?: string;
  }) => void;
}) => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const [email, setEmail] = useState("");
  const continueText = "Continue";

  const datasourceOptions: OptionObject<Datasource>[] = props.validDatasources
    .map((ds) => {
      return { value: ds, sectionLabel: "Available" };
    })
    .concat(
      props.invalidDatasources.map((ds) => {
        return { value: ds, enabled: false, sectionLabel: "Unavailable" };
      }),
    )
    .map((option) => {
      return {
        ...option,
        id: option.value.resourceId,
        label: option.value.name,
      };
    });

  const variableOptions: OptionObject<Variable>[] = props.validDatasources
    .filter((ds) => props.selectedDatasources?.includes(ds.resourceId))
    .map((ds) => {
      return ds.variables.map((variable) => {
        return {
          id: variable.resourceId,
          value: variable,
          label: variable.name,
          sectionLabel: ds.name,
        };
      });
    })
    .flat();

  switch (props.stageName) {
    case "region":
      return (
        <>
          <Text
            variant="primary"
            sx={{
              color: theme.colors.muted,
              fontWeight: theme.fontWeights.light,
            }}
          >
            Use the input boxes below or right-click the map to define a region.
          </Text>
          <Region
            header="North-West Corner"
            x={props.coordinates.west}
            y={props.coordinates.north}
            xChange={(e) =>
              props.onCoordinateChange({
                west: parseFloat(e.target.value),
              })
            }
            yChange={(e) =>
              props.onCoordinateChange({
                north: parseFloat(e.target.value),
              })
            }
          />
          <Region
            header="South-East Corner"
            x={props.coordinates.east}
            y={props.coordinates.south}
            xChange={(e) =>
              props.onCoordinateChange({
                east: parseFloat(e.target.value),
              })
            }
            yChange={(e) =>
              props.onCoordinateChange({
                south: parseFloat(e.target.value),
              })
            }
          />
          <Divider />
          <TransitionButton
            disabled={!validateCoordinates(props.coordinates)}
            nextStage={() => props.setStage("datasets")}
            text={continueText}
          />
        </>
      );

    case "datasets":
      return (
        <>
          {datasourceOptions.length === 0 && (
            <Text>Error: Couldn&apos;t fetch data sources.</Text>
          )}
          <OeCheckboxList
            options={datasourceOptions}
            selected={props.selectedDatasources ?? []}
            onChange={props.onDatasourceSelectionChange}
          />
          <Divider />
          <TransitionButton
            disabled={
              props.selectedDatasources === undefined ||
              props.selectedDatasources.length === 0
            }
            nextStage={() => props.setStage("variables")}
            text={continueText}
          />
        </>
      );
    case "variables":
      return (
        <>
          {/*<Heading variant="h2">BRAN2020</Heading>
        <Label variant="primary" sx={{ color: theme.colors.muted }}>
          https://research.csiro.au/bluelink/global/reanalysis99
    </Label>*/}
          <OeCheckboxList
            options={variableOptions}
            selected={props.selectedVariables ?? []}
            onChange={([variableId, selected]) =>
              props.onVariableSelectionChange([
                variableOptions.find((v) => v.id === variableId)!.value,
                selected,
              ])
            }
          />
          <Divider />
          <TransitionButton
            disabled={props.selectedVariables.length === 0}
            nextStage={() => props.setStage("time")}
            text={continueText}
          />
        </>
      );
    case "time":
      return (
        <>
          <OeCalendar
            heading="Calendar"
            startDate={props.startDate}
            endDate={props.endDate}
            onChange={props.onDateChange}
          />
          <Divider />
          <TransitionButton
            nextStage={() => props.setStage("submit")}
            text={continueText}
          />
        </>
      );
    case "submit":
      return (
        <>
          <Label variant="primary" sx={{ color: theme.colors.muted }}>
            Your email (so we can send you a link to your data)
          </Label>
          <Input
            variant="primary"
            sx={inputStyle}
            placeholder="yourname@csiro.au"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            defaultValue={email}
          ></Input>
          <HashLink
            to={PrivacyPageRoute}
            variant="primary"
            sx={{ color: theme.colors.primary, textDecoration: "none" }}
            target="_blank"
          >
            Privacy Policy
          </HashLink>
          <Divider />
          <DataRequestEmailButton
            coordinates={props.coordinates}
            dataSources={datasourceOptions
              .map((ds) => ds.value)
              .filter(
                (ds) => props.selectedDatasources?.includes(ds.resourceId),
              )}
            dates={props.selectedDates}
            email={email}
            selectedVariables={props.selectedVariables}
            setStage={props.setStage}
            setEmail={setEmail}
          />
        </>
      );
    case "done!":
      return (
        <>
          <Text>
            Thanks for your interest in Ocean Explorer. We will review your
            request and be in touch shortly.
          </Text>
          <Divider />
          <TransitionButton
            nextStage={() => {
              props.setStage("submit");
            }}
            text={"Go back"}
          />
          <Button
            variant="primary"
            sx={{ borderRadius: 2, height: "55px" }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Home
          </Button>
        </>
      );
    default:
      return null;
  }
};
