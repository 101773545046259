import { PageContainer } from "src/components/PageContainer";
import { Heading, Text, Link as ThemeUILink, Box, Button } from "theme-ui";
import { LandingPageRoute } from "./LandingPage";
import { Link as HashLink } from "react-router-dom";
import { PrivacyPageRoute } from "./PrivacyPage";

export const ContactPageRoute = "/home/contact";
export const ContactPage = () => {
  return (
    <PageContainer>
      <Heading as="h2" variant={"styles.h2"}>
        Contact Us
      </Heading>
      <Text as="p" variant={"styles.p"} sx={{ fontSize: 5 }}>
        <Text variant="muted">
          Ocean Explorer is the flagship product developed by
        </Text>{" "}
        Immersive Data Insights (IDI)
        <Text variant="muted">, a small team within</Text> CSIRO&apos;s Data61
        <Text variant="muted">
          . Please get in touch with any questions, comments, feedback, or just
          to say hello 👋
        </Text>
      </Text>
      <Text as="p" variant="muted" sx={{ fontSize: 4 }}>
        Email
      </Text>
      <Text as="p" variant={"styles.p"} sx={{ fontSize: 5 }}>
        <ThemeUILink href="mailto:immersivedata@csiro.au">
          ImmersiveData@csiro.au
        </ThemeUILink>
      </Text>
      <Text as="p" variant={"styles.p"}>
        Information on how we handle your information can be found in our{" "}
        <HashLink to={PrivacyPageRoute}>privacy policy</HashLink>.
      </Text>
      <Box sx={{ marginTop: "32px" }}>
        <HashLink to={LandingPageRoute}>
          <Button variant="secondary">← Return to home</Button>
        </HashLink>
      </Box>
    </PageContainer>
  );
};
