import type { ReactNode } from "react";
import { Link as HashLink } from "react-router-dom";
import { Box, Heading } from "theme-ui";
import { PrivacyPageRoute } from "src/pages/PrivacyPage";
import { LandingPageRoute } from "src/pages/LandingPage";
import { ContactPageRoute } from "src/pages/ContactPage";

interface PageContainerProps {
  children: ReactNode;
}
export const PageContainer = (props: PageContainerProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          top: 0,
          padding: "32px",
          marginBottom: "32px",
          borderBottomColor: "vars.border.section",
          borderBottomStyle: "solid",
          borderBottomWidth: 0,
        }}
      >
        <Heading as="h1" variant={"styles.h1"}>
          <HashLink
            to={LandingPageRoute}
            style={{
              textDecoration: "none",
              color: "#000000",
            }}
          >
            Ocean Explorer
          </HashLink>
        </Heading>
      </Box>

      <Box
        sx={{
          maxWidth: "1320px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingY: "40px",
          paddingX: "40px",
        }}
      >
        {props.children}
      </Box>

      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          padding: "32px",
          borderTopColor: "vars.border.section",
          borderTopStyle: "solid",
          borderTopWidth: 0,
          marginTop: "32px",
        }}
      >
        <HashLink to={LandingPageRoute}>Home</HashLink> |{" "}
        <HashLink to={ContactPageRoute}>Contact Us</HashLink> |{" "}
        <HashLink to={PrivacyPageRoute}>Privacy Policy</HashLink>
      </Box>
    </>
  );
};
