import type {
  CSSObjectWithLabel,
  GroupBase,
  OptionsOrGroups,
} from "react-select";
import Select from "react-select";
import { Box, useThemeUI, css, Label } from "theme-ui";
import type { OeComponentBase } from "./OeComponentBase";
import { type OceanExplorerTheme } from "./theme";
import { cloneDeep } from "lodash";

export interface OeDropdownProps extends OeComponentBase {
  labelText?: string;
  options?: OptionsOrGroups<unknown, GroupBase<unknown>>;
  onChange?: (option: { label: string; value: string }) => void;
  value?: any;
  isLoading?: boolean;
  variant?: keyof OceanExplorerTheme["dropdowns"]; // defaults to "primary"
}

export const OeDropdown = (props: OeDropdownProps) => {
  // We have to translate our styling to `react-select`'s. For more info on
  // how to style `react-select` components, look here:
  // https://react-select.com/styles#inner-components

  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const dropdownsThemeVariant = cloneDeep(
    theme.dropdowns[props.variant ?? "primary"],
  );

  // Extract the `<option> tag styles. react-select doesn't actually use
  // `option`, so we need to do some translation for compatibility
  const optionStyles = css(dropdownsThemeVariant["> option"])(theme);

  // Now get the outer control styles, removing the "> option" that we've
  // already extracted just in case it interferes with something.
  delete (dropdownsThemeVariant as any)["> option"];
  const controlStyles = css(dropdownsThemeVariant)(theme);

  const valueStyle = (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: theme.colors.vars.text.primary,
      fontFamily: theme.fonts.body,
      fontSize: theme.fontSizes[1],
      backgroundColor: controlStyles.backgroundColor,
    };
  };

  const zIndex = 100;

  const onChange = (props.onChange as any) || undefined;

  return (
    <Box sx={{ width: "100%", ...props.sx }}>
      {props.labelText !== undefined && (
        <Label sx={{ marginBottom: 2 }}>{props.labelText}</Label>
      )}
      <Select
        onChange={onChange}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          // We increase the zIndex of these so that they appear above the
          // OERange control (the toolcool-range-slider component in it seems to
          // have a max zIndex of 50)
          menuPortal: (base: any) => ({ ...base, zIndex }),
          menu: (base: any) => ({ ...base, zIndex }),
          control: (base: any) => {
            return {
              ...base,
              ...controlStyles,
            };
          },
          menuList: (base: any) => {
            return { ...base, ...controlStyles };
          },
          singleValue: valueStyle,
          option: (base: any) => {
            return {
              ...valueStyle(base),
              ...controlStyles,
              ...optionStyles,
            };
          },
        }}
        value={props.value}
        options={props.options}
        isLoading={props.isLoading ?? false}
      />
    </Box>
  );
};
