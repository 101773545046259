import { Box, Button, Text, Link as ThemeUILink, Image } from "theme-ui";
import { Link as HashLink } from "react-router-dom";
import { PublicDataRequestPageRoute } from "./PublicDataRequest/PublicDataRequestPage";
import { PageContainer } from "src/components/PageContainer";
import { ContactPageRoute } from "./ContactPage";

export const LandingPageRoute = "/";
export const LandingPage = () => {
  return (
    <PageContainer>
      <Text sx={{ fontSize: "32px" }}>
        Ocean Explorer <Text variant="muted">is an</Text> interactive
        visualisation platform{" "}
        <Text variant="muted">that helps researchers</Text> explore{" "}
        <span style={{ whiteSpace: "nowrap" }}>4-dimensional</span> ocean data{" "}
        <Text variant="muted">and</Text> communicate their findings.
      </Text>
      <br />

      <Box sx={{ marginTop: "16px" }}>
        <ThemeUILink href="/viewer/">
          <Button>Try public demo</Button>
        </ThemeUILink>
      </Box>

      <Image
        variant="rounded"
        sx={{ marginTop: "40px", width: "100%" }}
        src="/static/bran-preview.jpeg"
        alt="A preview of the Ocean Explorer viewer, showing a 3D model of the temperature of the ocean near the east coast of Australia. A panel on the left hand side reads: 'The Bluelink ReANalysis (BRAN) provides a 3D record of the global ocean. This map shows daily data for the East coast of Australia over the month of May 2020. The data is freely available to the public through NCI. The data catalogue includes a description of the dataset and how to access it. A paper by Chamberlain et al. (2021) is available with further details and results.'"
      />

      <Box
        variant="boxes.sectionRounded"
        sx={{
          paddingTop: "32px",
          paddingBottom: "32px",
          marginTop: "32px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Text sx={{ fontSize: 5 }}>
          Ready to get started?{" "}
          <HashLink to={PublicDataRequestPageRoute}>Request data</HashLink> from
          the public catalogue or{" "}
          <HashLink to={ContactPageRoute}>contact our team</HashLink>
        </Text>
      </Box>
    </PageContainer>
  );
};
