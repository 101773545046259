import type { Datasource } from "oex-common-model";
import { Button } from "theme-ui";
import type { Coordinates } from "./PublicDataRequestState";
import { StageName, stageNames } from "./stageNames";

export interface OeEmailButtonProps {
  dataSources: Datasource[];
  selectedVariables: string[];
  coordinates: Coordinates;
  dates: string;
  email: string;
  setStage: (stage: StageName) => void;
  setEmail: (email: string) => void;
}

export const DataRequestEmailButton = (props: OeEmailButtonProps) => {
  const formattedVariables = props.dataSources
    .map((ds) => {
      const variables = ds.variables
        // EJK 2024-07-23: This will only work if v.resourceId is unique across
        // all datasources. Right now they are, and I think they should be, but
        // all of our data schemas are still in flux
        .filter((v) => props.selectedVariables.includes(v.resourceId))
        .map((v) => `    - ${v.name}`);
      return `  ${ds.name}:\n${variables.join("\n")}`;
    })
    .join("\n");

  const coordinates = props.coordinates;
  function sendEmail() {
    const subject = `
TO COMPLETE YOUR DATA REQUEST, PLEASE SEND THIS EMAIL WITH THE METADATA PROVIDED BELOW.

For more information on our privacy policy, see https://fishtank.space/#/home/privacy


-

Ocean Explorer Data Request - ${new Date()}

-

Source Data:
${formattedVariables}

Region:
  North: ${coordinates.north}
  East: ${coordinates.east}
  South: ${coordinates.south}
  West: ${coordinates.west}

Time Range:
${props.dates}

-

Thank you!
  `;

    const encodedDataRequestDateString = encodeURIComponent(subject);

    window.location.href =
      "mailto:immersivedata@csiro.au?subject=Ocean Explorer Data Request&body=" +
      encodedDataRequestDateString;

    props.setEmail("");
    props.setStage("done!");
  }

  function validateEmail() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(props.email);
  }

  return (
    <Button
      variant="primary"
      sx={{ borderRadius: 2, height: "55px" }}
      onClick={sendEmail}
      disabled={!validateEmail()}
    >
      Submit Request
    </Button>
  );
};
